@import '../../../resources/scss/colors.scss';

.insightsContent {
  display: flex !important;
  gap: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

}

.insightsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  @media (max-width: 768px) {
    gap: 2rem;
  }
}

.title {
  display: flex !important;
  color: $black !important;
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 56px !important;

  @media (max-width: 768px) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

.titleHighlighted {
  display: flex !important;
  color: $app-primary !important;
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 56px !important;

  @media (max-width: 768px) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

.insightGrid {
  align-items: center;
  justify-content: center;
}

.valueWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}

.valueCaption {
  overflow-wrap: break-word;
  word-break: break-word;
  text-align: center;
  font-size: 1rem !important;
  line-height: 24px !important;
}

.insight-img-wrapper {
  height: auto;
  margin: 10px auto;
  margin-left: auto !important;
  margin-right: auto !important;
}

.insight-img {
  height: auto;
  // max-width: 100%;
}