@import "../../../resources/scss/colors.scss";

.servicesContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;

  }
}

.servicesTabList {
  border-radius: 12px !important;
  width: 40% !important;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.servicesTabContent {
  width: 60% !important;
  height: 350px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.1607843137) !important;
  border-radius: 12px !important;
  padding: 0 !important;
  gap: 0 !important;

  @media (max-width: 768px) {
    width: 100% !important;
    height: auto !important; 
    flex-direction: column;
    width: 100% !important;
    // display: none; // Initially hidden for mobile
  }
}

.tabSelected {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  background-color: $app-primary !important;
  color: $white !important;
  border-radius: 12px !important;
  transition: 0.1s;
  align-items: flex-start !important;
  height: 3.75rem !important;

  @media (max-width: 768px) {
    height: auto !important;
  }
}

.tabNotSelected {
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  width: 100%;
  color: $black !important;
  align-items: flex-start !important;
  height: 3.75rem !important;
  border-radius: 12px !important;
  

  @media (max-width: 768px) {
    height: auto !important;
  }
}

.tabNotSelected:hover {
  background-color: $app-primary-10 !important;
  border-radius: 12px !important;
}

.tabImgWrapper {
  text-align: center;
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.tabImg {
  width: 100%;
  height: 100%;
}

.tabTextWrapper {
  width: 40%;
  padding: 20px;
  align-self: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.tabImgContent {
  position: relative;
  width: 100%;
}

.tabText {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 20px;
  color: $black;
  font-size: 18px;
  line-height: 30px;
}

.tabTextButton {
  border-radius: 50px !important;
  gap: 0.75rem;
  font-size: 16px;
}

.tabTextContent {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}