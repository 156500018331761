@import '../../../resources//scss/colors.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem !important;

    @media (max-width: 768px) {
        gap: 1rem !important;
    }
}

.titleText {
    display: flex !important;
    font-size: 3rem !important;
    font-weight: 700 !important;

    @media (max-width: 768px) {
        font-size: 1.5rem !important;
        font-weight: 700 !important;
    }
}

.titleTextHighlight {
    font-size: 3rem !important;
    font-weight: 700 !important;
    color: $app-primary !important;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 1.5rem !important;
        font-weight: 700 !important;
        text-align: center;
    }
}



.valueDeliverContainer {
    display: flex;
}

.valueDeliverGrid {
    height: 100%;
}

.valueDeliverCard {
    height: 100% !important;
    border-radius: 12px !important;
    /* Smooth transition for transform, background-color, and color */
    transition: transform 0.3s ease-in-out !important;
}

.valueDeliverCard:hover {
    background-color: #7255F7;
    color: white;
    transform: scale(1.03) !important;
    /* More noticeable zoom */
    /* Add shadow for depth */
    box-shadow: 0 10px 20px $top-nav-shadow !important;
}

.cardTitle {
    font-size: 1.35rem !important;
    margin-bottom: 1rem !important;
    font-weight: 600 !important;

    @media (max-width: 768px) {
        font-size: 1.25rem !important;
    }
}

.cardDescription {
    font-size: 1rem !important;
}