    .mainContainer {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        gap: 1rem;

        @media (min-width: 1024px) {
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 2rem;
            gap: 2rem;
        }

        @media (min-width: 1440px) {
            padding-left: 12rem;
            padding-right: 12rem;
        }

        @media (min-width: 1920px) {
            padding-left: 24rem;
            padding-right: 24rem;
        }
    }

    .heroSection {
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-position: right;
        overflow: hidden;
    }

    .servicesSection {
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-position: right;
        overflow: hidden;
        justify-content: center;
    }