@import '../../../resources/scss/colors.scss';

.navStyle {
    position: sticky !important;
    top: 0 !important;
    z-index: 99 !important;
    width: 100% !important;
    box-shadow: 0 2px 5px 1px $top-nav-shadow !important;
    background-color: $top-nav-bg !important;
    /* Semi-transparent lavender background */
    backdrop-filter: blur(7px) !important;
    /* Blur effect */
    -webkit-backdrop-filter: blur(7px) !important;
    /* For older versions of Safari */
    height: 4rem !important;
}

.navDefault {
    background-color: transparent !important;
    /* Semi-transparent lavender background */
    position: sticky !important;
    top: 0 !important;
    z-index: 99 !important;
    width: 100% !important;
    box-shadow: 0 2px 5px 1px $top-nav-shadow !important;
    backdrop-filter: blur(7px) !important;
    /* Blur effect */
    -webkit-backdrop-filter: blur(7px) !important;
    /* For older versions of Safari */
    height: 4rem !important;
}

.navLink {
    display: flex;
    padding: 0.8rem 1.2rem !important;
    font-weight: 500 !important;
    transition: color 0.3s ease !important;
    font-size: 1rem !important;
    color: $black !important;
    border-radius: 12px !important;

    &.active {
        color: $app-primary !important;
    }

    &:hover:after {
        color: $app-primary !important;
    }

    &:hover {
        background-color: rgba(114, 85, 247, 0.04);
    }
}

.navBrn {
    font-weight: 700;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
}

.navContainer {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    height: 4rem !important;
}

.navAppIcon {
    max-height: 3rem;
    max-width: 3rem;    
}

.navTech {
    color: $app-primary;
    font-size: 0.50rem;
    width: 100%;
    margin-top: 4px;
    text-align: end;
}

.navHighlight {
    color: $app-primary;
    margin-left: 0.25rem;
}

.navNoHighlight {
    color: $black;
}

.drawerPaper {
    background-color: transparent !important;
    box-shadow: none !important;
    width: 100% !important;
    height: 100% !important;
    padding-top: 64px !important;
}