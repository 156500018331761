@import '../../../resources/scss/colors.scss';

.heroContainer {
    min-height: calc(100vh - 12rem) !important;
    margin-top: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 4rem !important;
    align-items: center !important;

    @media (max-width: 768px) {
        gap: 2rem !important;
        align-items: flex-start !important;
    }
}

.heroTitle {
    font-size: 4rem !important;
    font-weight: 700 !important;
    text-align: left !important;

    @media (max-width: 768px) {
        font-size: 2rem !important;
    }
}

.heroHighlightText {
    font-size: 4rem !important;
    font-weight: 700 !important;
    text-align: left !important;
    color: $app-primary;
    white-space: 'nowrap';

    @media (max-width: 768px) {
        font-size: 2rem !important;
        text-align: center !important;
    }
}

.heroSubTextContainer {
    display: flex;
    flex-direction: column;
}

.heroSubText {
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    color: $black !important;
    text-align: center !important;

    @media (max-width: 768px) {
        font-size: 1rem !important;
        text-align: left !important;
    }
}

.heroButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}