@import "../../../resources/scss/colors.scss";

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
}

.logoAppIcon {
  height: 80%;
}

.brnIcon,
.logoHighlight {
  color: $white;
  margin-left: 0.25rem;
}

.navContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  height: 4rem !important;
}

.navBrn {
  font-weight: 700;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}
.navAppIcon {
  max-height: 3rem;
  max-width: 3rem;
}

.navHighlight {
  color: $app-primary;
  margin-left: 0.25rem;
}

.navNoHighlight {
  color: $black;
}

.footer {
  padding-left: 1rem;
  padding-right: 1rem;

  * > p {
    line-height: 2 !important;
  }

  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 1440px) {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  @media (min-width: 1920px) {
    padding-left: 24rem;
    padding-right: 24rem;
  }
}

.bottomLinkLeft {
  padding-left: 2rem;
  padding-right: 2rem;

  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 0;
  }

  @media (min-width: 1440px) {
    padding-left: 12rem;
    padding-right: 0;
  }

  @media (min-width: 1920px) {
    padding-left: 24rem;
    padding-right: 0;
  }
}

.bottomLinkRight {
  @media (min-width: 1024px) {
    padding-right: 2rem;
  }

  @media (min-width: 1440px) {
    padding-right: 12rem;
  }

  @media (min-width: 1920px) {
    padding-right: 24rem;
  }
}

.servicesList {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.dialog {

  .MuiDialog-paper{
    background: linear-gradient(to bottom right, #7255f750, #ffffff);
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
}
