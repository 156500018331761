body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /* background: linear-gradient(to bottom right, #dbc6ff, #ffffff); */
  background: linear-gradient(to bottom right, #7255f750, #ffffff);
  background-attachment: fixed;
  background-repeat: no-repeat;
}

html,
body {
  height: 100vh;
}

#root {
  height: 100vh;
}

#content {
  scroll-margin-top: 3rem;
}

@media (min-width: 769px) {
  #content {
    scroll-margin-top: 4rem;
  }
}