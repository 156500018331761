// List of all colors used across the project

// PRIMARY COLOR hex values include the opacity levels in the alpha channel (last two characters).
$app-primary: #7255F7;
$app-primary-10: #7255F71A;
$app-primary-20: #7255F733;
$app-primary-30: #7255F74D;
$app-primary-40: #7255F766;
$app-primary-50: #7255F780;
$app-primary-60: #7255F799;
$app-primary-70: #7255F7B3;
$app-primary-80: #7255F7CC;
$app-primary-90: #7255F74D;

// BoxShadow Color
$top-nav-shadow: #7255F780;
$top-nav-shadow-black: #403C4329;

// Background Color
$top-nav-bg: #E3E3FFE6;

// White, Black
$white: #FFFFFF;
$black: #000000;

// Grey
$grey-10: #F4F4F4;
$grey-20: #EBEBEB;
$grey-30: #E4E5E5;
$grey-40: #D7D7D7;
$grey-50: #CECFCD;
$grey-60: #C3C4C2;
$grey-70: #AFB0AF;
$grey-80: #7D7D7D;
$grey-90: #676868;
$grey-100: #515252;


// Blue
$core-blue-10: #B3CDDE;
$core-blue-20: #8AB0C7;
$core-blue-30: #598AAA;
$core-blue-40: #255F82;
$core-blue-50: #013D58;
$core-blue-60: #10253F;
$core-blue-70: #00132B;

// Yellow
$accent-yellow: #FFF1D0;
$custom-yellow-10: #846003;

// Red
$interaction-red-10: #FFE5E6;
$interaction-red-20: #FF989A;
$interaction-red-30: #FB5053;
$interaction-red-40: #F23639;
$interaction-red-50: #CC2427;
$interaction-red-60: #BA2629;
$interaction-red-70: #A3282B;

// Green
$interaction-green-10: #D9FAD1;
$interaction-green-20: #A1F28C;
$interaction-green-30: #4BC92C;
$interaction-green-40: #30A512;
$interaction-green-50: #25810E;
$interaction-green-60: #1E6A0B;
$interaction-green-70: #185309;
$accent-green: #DAE8D8;

// Text
$text-base-dark: #141414;
$text-base-light: #FFFFFF;
$text-subdued-dark: #3D3D3D;
$text-subdued-light: #696969;
$text-disabled: #7D7D7D;
$text-action: #0276B1;
$text-positive: #1E6A0B;
$text-negative: #CC2427;

// Button
$button-disabled: #F4F4F4;
$button-action-base: #0276B1;
$button-action-hover: #026597;
$button-action-active: #014E74;
$button-positive-base: #25810E;
$button-positive-hover: #1E6A0B;
$button-positive-active: #185309;
$button-negative-base: #CC2427;
$button-negative-hover: #BA2629;
$button-negative-active: #A3282B;