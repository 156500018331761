@import "../../../resources//scss/colors.scss";

.partnersContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    gap: 1rem;
  }

  .carasouelContainer {
    .react-multi-carousel-list {
      height: 200px;
    }

    .react-multi-carousel-track {
      height: 200px;
      width: 200px;
    }

    .react-multi-carousel-item {
      height: 200px;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    background-color: transparent;
    mix-blend-mode: color-burn;
  }

  .image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    mix-blend-mode: color-burn;
    padding: 1rem;

    @media (min-width: 1024px) {
      padding: 4rem;
    }

    @media (min-width: 1440px) {
      padding: 2rem;
    }

    &:hover {
      transform: scale(1.1) !important;
      transition: transform 0.3s ease-in-out !important;
    }
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .titleText {
    display: flex !important;
    font-size: 3rem !important;
    font-weight: 700 !important;
    justify-content: center;

    @media (max-width: 768px) {
      font-size: 1.5rem !important;
      font-weight: 700 !important;
    }
  }

  .titleTextHighlight {
    font-size: 3rem !important;
    font-weight: 700 !important;
    color: $app-primary !important;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.5rem !important;
      font-weight: 700 !important;
      text-align: center;
    }
  }
}
