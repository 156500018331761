.container {
    display: flex;
    align-items: stretch;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    @media (max-width: 192px) {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}

.aboutUsContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 50%;
    padding: 2rem 4rem;
    gap: 2rem;
    margin-top: 4rem;
    padding-top: 4rem;

    @media (max-width: 768px) {
        width: 100%;
        padding: 2rem;
        gap: 2rem;
    }
}

.contactUsFormContainer {
    padding: 2rem;
    width: 50%;
    box-shadow: 0 2px 20px 2px rgba(64, 60, 67, 0.16);
    border-radius: 12px;
    background-color: #7255F7;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2rem;
    margin-top: 4rem;
    padding-top: 4rem;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.countryPhoneContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.submitButton {
    color: #7255F7 !important;
    background-color: #FFF !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    border-radius: 50px !important;
    padding: 8px 24px !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;

    &:hover {
        background-color: #E3E3FFE6 !important;
    }
}